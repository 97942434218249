import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Header from "../components/Header"
import PrismicPageHero from "../components/slices/PrismicPageHero"
import PrismicPageText from "../components/slices/PrismicPageText"
import PrismicPageColumns from "../components/slices/PrismicPageColumns"
import PrismicPageProjectsList from "../components/slices/PrismicPageProjectsList"
import Footer from "../components/Footer"
import PageAnimation from "../components/PageAnimation"
import PrismicPageImages from "../components/slices/PrismicPageImages"
import PrismicPageEmail from "../components/slices/PrismicPageEmail"
import PrismicPageImageGallery from "../components/slices/PrismicPageImageGallery"
import PrismicPageExplorationCard from "../components/slices/PrismicPageExplorationCard"
import PrismicPageFullWidthImage from "../components/slices/PrismicPageFullWidthImage"
import PrismicPageBooks from "../components/slices/PrismicPageBooks"
import PrismicPageBookshelf from "../components/slices/PrismicPageBookshelf"
import CloseButton from "../components/CloseButton"
import { Spacer } from "./Spacer"
import Seo from "../components/SEO"
function Page({ data, location }) {
  const slices = get(data, "prismicPage.data.body")
  const hideHeader = get(data, "prismicPage.data.hide_header")
  console.log(data.seo)
  return (
    <>
      <PageAnimation location={location} hideHeader={hideHeader}>
        <Seo data={get(data, "seo.data")} />

        {slices &&
          slices.map((slice, index) => {
            switch (slice.__typename) {
              case "PrismicPageBodyHero":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageHero slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyText":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageText slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyColumns":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageColumns slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyProjectsList":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageProjectsList slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyImages":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageImages slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyEmail":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageEmail slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyImageGallery":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageImageGallery slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyExplorationCard":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageExplorationCard slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyFullWidthImage":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageFullWidthImage slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyBooks":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageBooks slice={slice} />
                  </Spacer>
                )
              case "PrismicPageBodyBookshelf":
                return (
                  <Spacer key={index} slice={slice}>
                    <PrismicPageBookshelf slice={slice} />
                  </Spacer>
                )

              default:
                return null
              // return <pre >{JSON.stringify(slice, null, 2)}</pre>
            }
          })}
      </PageAnimation>
    </>
  )
}
export default Page

export const query = graphql`
  query PageQuery($uid: String!) {
    seo: prismicPage(uid: { eq: $uid }) {
      data {
        metatitle
        metakeywords
        metadescription
        metaimage {
          url
        }
      }
    }
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        hide_header
        body {
          ... on PrismicPageBodyText {
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              alignment
              list
              narrow
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyHero {
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              constraint_image
              mobile_image {
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
                dimensions {
                  width
                }
              }
              image {
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
                dimensions {
                  width
                }
              }
              content {
                raw
              }
            }
          }
          ... on PrismicPageBodyProjectsList {
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              text {
                raw
              }
            }
            items {
              project {
                document {
                  ... on PrismicProject {
                    data {
                      date(formatString: "YYYY")
                      title {
                        text
                      }
                      where
                      description
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyColumns {
            id
            items {
              text {
                raw
              }
            }
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              text_alignment
            }
          }
          ... on PrismicPageBodyEmail {
            id
            primary {
              alignement: alignment
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
            }
          }
          ... on PrismicPageBodyImages {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              alignement
            }
            items {
              link
              target
              image {
                dimensions {
                  width
                }
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              mobile_image {
                dimensions {
                  width
                }
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyImageGallery {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
            }
            items {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyExplorationCard {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              flip
              image_vertical_align
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              text {
                raw
              }
              title: title1 {
                raw
              }
            }
          }
          ... on PrismicPageBodyFullWidthImage {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              image {
                url
                fluid {
                  ...GatsbyPrismicImageFluid
                }
                dimensions {
                  width
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyBooks {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              text {
                raw
              }
            }
            items {
              book {
                document {
                  ... on PrismicBook {
                    id
                    data {
                      cover {
                        alt
                        fluid(maxWidth: 150) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      link
                      target
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyBookshelf {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              text {
                raw
              }
            }
          }
        }
      }
    }
  }
`
