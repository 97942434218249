import get from "lodash.get"
import React from "react"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import GatsbyImage from "gatsby-image"
import TextRenderer from "../TextRenderer"
export default function PrismicPageHero({ slice }) {
  const raw = get(slice, "primary.content.raw", "")
  const fluid = get(slice, "primary.image.fluid")
  const constraint_image = get(slice, "primary.constraint_image")
  const mfluid = get(slice, "primary.mobile_image.fluid")
  const maxWidth = get(slice, "primary.image.dimensions.width")
  return (
    <>
      <div className="container  ">
        <div
          className="grid items-center justify-between gap-12"
          css={css`
            grid-template-rows: min-content;
            @media (min-width: 1024px) {
              ${constraint_image
                ? "grid-template-columns: minmax(auto, 560px) 280px;"
                : "grid-template-columns:repeat(2, 1fr);"}
            }
          `}
        >
          <div className="  lg:hidden" style={{ maxWidth: 604 }}>
            {mfluid && (
              <div>
                <GatsbyImage fluid={mfluid} />
              </div>
            )}
          </div>
          <div
            css={css`
              .social-links {
                ${tw`mt-8 phone:-mt-1`}
                color: #5b6377;
              }
            `}
          >
            <TextRenderer raw={raw} />
          </div>
          <div className="  tablet:hidden">
            {fluid && (
              <div style={{ maxWidth }}>
                <GatsbyImage fluid={fluid} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
