import React from "react"
import { css } from "@emotion/core"
import get from "lodash.get"
import GatsbyImage from "gatsby-image"
export default function PrismicPageImageGallery({ slice }) {
  const image1 = get(slice, "items[0].image.fluid")
  const image2 = get(slice, "items[1].image.fluid")
  const image3 = get(slice, "items[2].image.fluid")
  return (
    <div
      className="  grid     items-center justify-center "
      css={css`
        max-width: 960px;
        width: calc(100% - 2rem);
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        @media (max-width: 1279px) {
          width: calc(100% - 4rem);
          grid-auto-flow: row dense;
          .right-side {
            grid-column: 2 / span 1;
          }
          .center {
            grid-column: 1 / span 2;
            order: -20;
          }
        }
        @media (min-width: 1280px) {
          grid-gap: 28px;
          max-width: 100%;
          grid-template-columns: minmax(auto, 224px) minmax(auto, 864px) minmax(
              auto,
              224px
            );
        }
      `}
    >
      <div className="left-side">
        {image1 && <GatsbyImage fluid={image1} />}
      </div>
      <div className="center">{image2 && <GatsbyImage fluid={image2} />}</div>{" "}
      <div className="side">{image3 && <GatsbyImage fluid={image3} />}</div>
    </div>
  )
}
