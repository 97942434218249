import GatsbyImage from "gatsby-image"
import get from "lodash.get"
import React from "react"
import { css } from "@emotion/core"
export default function PrismicPageFullWidthImage({ slice }) {
  const url = get(slice, "primary.image.url")
  const alt = get(slice, "primary.image.alt")
  const fluid = get(slice, "primary.image.fluid")
  return (
    <div
      className="  container "
      css={css`
        @media (min-width: 768px) {
          max-width: calc(100% - 2rem);
        }
        @media (min-width: 1024px) {
          max-width: 1130px;
        }
      `}
    >
      {fluid && <GatsbyImage {...{ fluid, alt }} />}
    </div>
  )
}
