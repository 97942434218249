import get from "lodash.get"
import React from "react"
import TextRenderer from "../TextRenderer"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import cn from "classnames"
import GatsbyImage from "gatsby-image"

export default function PrismicPageImages({ slice }) {
  const alignement = get(slice, "primary.alignement")
  const items = get(slice, "items")
  console.log(slice)
  return (
    <div className={cn("container ")}>
      <div
        className={cn("flex flex-wrap items-center", {
          "justify-center": alignement == "Center",
          "justify-center": alignement == "Center",
          "justify-end": alignement == "Right",
          "justify-start": alignement == "Left",
        })}
      >
        {" "}
        {items &&
          items.map((item, index) => {
            return (
              <div
                key={index}
                css={css`
                  width: 100%;
                  max-width: ${get(item, "mobile_image.dimensions.width") +
                  "px"};
                  @media (min-width: 768px) {
                    max-width: ${get(item, "image.dimensions.width") + "px"};
                  }
                `}
              >
                <a
                  href={get(item, "link") || "#"}
                  target={get(item, "target") || "_blank"}
                  className={cn("w-full   tablet:mb-8", {
                    "mx-4": alignement == "Center",
                    "ml-8": alignement == "Right",
                    "mr-8": alignement == "Left",
                  })}
                >
                  <GatsbyImage
                    className={cn({
                      "phone:hidden": get(item, "mobile_image.fluid"),
                    })}
                    fluid={get(item, "image.fluid")}
                  />
                  {get(item, "mobile_image.fluid") && (
                    <GatsbyImage
                      className={"md:hidden"}
                      fluid={get(item, "mobile_image.fluid")}
                    />
                  )}
                </a>
              </div>
            )
          })}
      </div>
    </div>
  )
}
