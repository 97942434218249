import get from "lodash.get"
import React, { useEffect, useState } from "react"
import TextRenderer from "../TextRenderer"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import cn from "classnames"
import GatsbyImage from "gatsby-image"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { FaCheck, FaSpinner } from "react-icons/fa"
import * as yup from "yup"
const encode = (data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k])
  })
  return formData
}

export default function PrismicPageEmail({ slice }) {
  const [isSuccess, setSuccess] = useState(false)
  const alignement = get(slice, "primary.alignement")
  console.log(slice)
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }
  }, [isSuccess])

  return (
    <div className={cn("container ")}>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={yup.object({
          email: yup
            .string()
            .email("This has to be a valid email")
            .required("Email is required"),
        })}
        onSubmit={async (values, actions) => {
          fetch("/", {
            method: "POST",
            body: encode({ ...values, "form-name": "access" }),
          })
            .then(() => {
              setSuccess(true)
              actions.resetForm()
            })
            .catch((error) => {
              alert(error)
            })
        }}
      >
        {({ isSubmitting, isValid, values, submitForm }) => {
          return (
            <Form
              name="access"
              method="post"
              data-netlify="true"
              id="email-form"
              className={cn("   max-w-md  ", {
                "mx-auto": alignement == "Center",
                "mr-auto": alignement == "Left",
                "ml-auto": alignement == "Right",
              })}
            >
              <input type="hidden" name="form-name" value="access" />
              <div className="flex rounded-lg overflow-hidden w-full">
                <Field
                  name="email"
                  type="email"
                  disabled={isSubmitting || isSuccess}
                  placeholder="Enter your Email"
                  style={{ minWidth: 0 }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault()
                      submitForm()
                    }
                  }}
                  className="bg-light py-3 px-5  flex-grow outline-none"
                />
                <button
                  disabled={isSubmitting || !isValid}
                  css={css``}
                  type="submit"
                  className="blue-gradient flex-shrink-0 disabled:cursor-not-allowed inline-flex items-center space-x-2  py-3 phone:px-4 px-6 font-bold focus:outline-none"
                >
                  <span>{isSuccess ? "Submitted" : "Submit"}</span>
                  {isSubmitting && <FaSpinner className="animate-spin" />}
                  {isSuccess && <FaCheck />}
                </button>
              </div>
              {values["email"].trim() !== "" && (
                <ErrorMessage name={"email"}>
                  {(msg) => (
                    <div
                      className="text-sm text-center mt-2  "
                      style={{ color: "#EE0200" }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
