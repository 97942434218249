import GatsbyImage from "gatsby-image"
import get from "lodash.get"
import React from "react"
import TextRenderer from "../TextRenderer"
import { css } from "@emotion/core"
export default function PrismicPageBooks({ slice }) {
  const text = get(slice, "primary.text.raw")
  const items = get(slice, "items")
  return (
    <div>
      <div className="container">
        <div className="text-center">
          <TextRenderer raw={text} />
        </div>
        {items && (
          <div className="flex flex-wrap items-center justify-center  my-12">
            {items.map(({ book }, index) => {
              console.log(book)
              const link = get(book, "document.data.link")
              const target = get(book, "document.data.target")
              const fluid = get(book, "document.data.cover.fluid")
              const Tag = link ? "a" : "div"
              return (
                <Tag
                  href={link}
                  target={target}
                  key={index}
                  className="inline-block mx-4 mb-6"
                  css={css`
                    max-width: 150px;
                    width: 40%;
                  `}
                >
                  {fluid && (
                    <div className="hover:shadow-book transition-shadow duration-300 rounded-sm">
                      <GatsbyImage fluid={fluid} />
                    </div>
                  )}
                </Tag>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
