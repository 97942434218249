import get from "lodash.get"
import React from "react"
import TextRenderer from "../TextRenderer"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import cn from "classnames"

export default function PrismicPageColumns({ slice }) {
  const text_alignment = get(slice, "primary.text_alignment")
  const items = get(slice, "items")
  return (
    <div className="container columns">
      {items && (
        <div
          className={cn("row  ", {
            "justify-center": !text_alignment,
          })}
        >
          {items.map((item, index) => {
            return (
              <div
                className="col w-full sm:w-1/2 lg:w-1/3  "
                key={index}
                css={css`
                  ${index > 1 && tw`md:mt-8`}
                  ${index > 2 && tw`lg:mt-8`}
                  ${index < 3 && tw`lg:mt-0`}
                  ${text_alignment && "text-align: center;"}
                  p {
                    ${tw`mt-0 `}
                  }
                  p + p {
                    ${tw`mb-1`}
                  }
                `}
              >
                <TextRenderer raw={get(item, "text.raw")} />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
