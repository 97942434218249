import get from "lodash.get"
import React, { useMemo, useState } from "react"
import TextRenderer from "../TextRenderer"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import cn from "classnames"
import Select from "react-select"
import { graphql, useStaticQuery } from "gatsby"
export default function PrismicPageBookshelf({ slice }) {
  const { allAirtable } = useStaticQuery(graphql`
    {
      allAirtable(filter: { data: { Name_of_Book: { ne: null } } }) {
        nodes {
          data {
            Amazon_Link
            Book_Cateogry
            Name_of_Book
          }
        }
      }
    }
  `)
  const text = get(slice, "primary.text.raw")
  const [categorySelected, setCategorySelected] = useState(null)
  const categories = useMemo(() => {
    const cat = new Set()
    allAirtable.nodes.forEach((item) => {
      const category = get(item, "data.Book_Cateogry")
      if (category) {
        cat.add(category)
      }
    })
    return Array.from(cat).sort()
  })
  const books = useMemo(() => {
    const books = allAirtable.nodes.map((item) => {
      const category = get(item, "data.Book_Cateogry")

      const title = get(item, "data.Name_of_Book")
      const link = get(item, "data.Amazon_Link")
      const target = "_blank"
      return {
        title,
        link,
        target,
        category,
      }
    })
    return books.sort((a, b) => {
      var nameA = a.title.toUpperCase()
      var nameB = b.title.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  })

  return (
    <div className="container">
      <TextRenderer raw={text} />
      <div
        className="my-12 grid gap-4 items-stretch "
        css={css`
          @media (min-width: 768px) {
            grid-template-columns: 196px 1px 1fr;
          }
        `}
      >
        <div className="sticky top-4">
          <div className="phone:mb-2 mb-8">Category</div>
          <div
            className=" md:hidden"
            css={css`
              .select__ {
                &indicator-separator {
                  display: none;
                }
                &control,
                &indicator {
                  ${tw`text-text`}
                }

                &control {
                  ${tw`bg-light border-none`}
                }
                &control--is-focused {
                  ${tw`shadow-inner`}
                }
                &option--is-selected {
                  ${tw`bg-dusk`}
                }
                &option--is-focused {
                  ${tw`bg-light text-text`}
                }
              }
            `}
          >
            <Select
              classNamePrefix="select"
              options={[
                { label: "All Books", value: null },
                ...categories.map((c) => ({ label: c, value: c })),
              ]}
              value={
                categorySelected
                  ? {
                      label: categorySelected,
                      value: categorySelected,
                    }
                  : null
              }
              onChange={(e) => {
                setCategorySelected(e.value)
                console.log(e)
              }}
            />
          </div>

          <ul
            className="phone:hidden space-y-4     "
            css={css`
              li.active {
                ${tw`text-primary-DEFAULT font-bold`}
              }
            `}
          >
            <li
              onClick={() => {
                setCategorySelected(null)
              }}
              className={cn("cursor-pointer  hover:text-primary", {
                active: categorySelected === null,
              })}
            >
              All Books
            </li>
            {categories.map((category) => {
              return (
                <li
                  onClick={() => {
                    setCategorySelected(category)
                  }}
                  className={cn("cursor-pointer  hover:text-primary", {
                    active: categorySelected === category,
                  })}
                  key={category}
                >
                  {category}
                </li>
              )
            })}
          </ul>
        </div>
        <div className=" md:border-l border-dashed border-dusk"></div>
        <div>
          <div className="mb-8 md:pl-12  ">Book Title</div>
          <div className="md:pl-12">
            <ul
              className="  space-y-4 "
              css={css`
                padding-left: 1.4rem;
                list-style: disc;
                a {
                  /* display: inline-block; */
                  border-bottom: 2px dashed transparent;
                  &:hover {
                    color: #5b6377;
                    border-bottom: 2px dashed #5b6377;
                  }
                }
              `}
            >
              {books
                .filter((book) => {
                  if (!categorySelected) {
                    return true
                  } else {
                    return categorySelected === book.category
                  }
                })
                .map((book) => {
                  return (
                    <li className="text-charcoal   " key={book.title}>
                      <a href={book.link} target={"_blank"}>
                        {" "}
                        {book.title}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
