import get from "lodash.get"
import React, { useMemo, useState } from "react"
import TextRenderer from "../TextRenderer"
import { css } from "@emotion/core"
import Collapse from "../Collapse"
import { motion } from "framer-motion"
export default function PrismicPageProjectsList({ slice }) {
  const raw = get(slice, "primary.text.raw", {})
  const [isOpen, setIsOpen] = useState(false)
  let list = useMemo(() => {
    const items = get(slice, "items")
    if (items) {
      return items.map((item) => {
        const { date, title, where, description } = get(
          item,
          "project.document.data"
        )
        return {
          date,
          title: get(title, "text"),
          where,
          description,
        }
      })
    } else {
      return null
    }
  })

  return (
    <>
      <div className="container    ">
        <div className="prose lg:prose-xl">
          {" "}
          <TextRenderer raw={raw} />{" "}
        </div>
        <div className="mt-4">
          {list && (
            <div>
              {" "}
              {list.slice(0, isOpen ? list.length : 3).map((item, index) => {
                return (
                  <div
                    className="grid py-10 border-b-2 last:border-b-0 border-dashed border-feather"
                    css={css`
                      column-gap: 1rem;

                      @media (min-width: 1024px) {
                        column-gap: 2rem;
                        grid-template-columns: 64px 224px 184px 1fr;
                      }
                    `}
                  >
                    <div className="  tablet:text-base">{item.date}</div>
                    <div className="text-dark tablet:text-lg">{item.title}</div>
                    <div className="  tablet:text-base">{item.where}</div>
                    <div className="  tablet:text-base">{item.description}</div>
                  </div>
                )
              })}
            </div>
          )}
        </div>

        {!isOpen && (
          <div
            onClick={() => {
              setIsOpen(true)
            }}
            className="border-b-2 cursor-pointer border-dashed border-dusk  hover:text-gradient phone:text-base text-lg lg:mt-4 inline-block"
          >
            Show Entire List
          </div>
        )}
      </div>
    </>
  )
}
