import get from "lodash.get"
import React from "react"
import TextRenderer from "../TextRenderer"
import cn from "classnames"
import { css } from "@emotion/core"

export default function PrismicPageText({ slice }) {
  const raw = get(slice, "primary.text.raw", "")
  const alignment = get(slice, "primary.alignment", false)
  const narrow = get(slice, "primary.narrow", false)
  const list = get(slice, "primary.list", false)
  return (
    <div
      className={cn("container", {
        list,
        narrow,
      })}
    >
      <div
        css={css`
          @media (min-width: 1024px) {
            ${narrow && "max-width:820px"}
          }

          .list & .prose,
          .list & .prose-xl {
            ul > li {
              padding-left: 0;
              &:before {
                display: none;
              }
            }
          }
        `}
        className={cn("max-w-none", {
          "text-center": alignment,
          "mx-auto": narrow,
        })}
      >
        <TextRenderer raw={raw} />
      </div>
    </div>
  )
}
